.item_list{
  border: solid 1px #fefdfd;
  color: #fefdfd;
  margin: 10px 0px;
}

.listItemName span{
  font-size: 1.7rem;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.listItemPrice span{
  font-size: 1.4rem;
}

.counter_button_box{
  border: #fefdfd solid 1px;
  height: 23px;
  margin-left: 20px;
  border-radius: 10px;
  color: #121212;
}

.counter_button_box span{
  font-size: 1.3rem;
}

.button_counter{
  border-radius: 50px;
  border: none;
  font-size: 15px;
  width: 20px;
}

.button_counter:hover{
  background-color: #61a6ab; 
}

.delete_box{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 0px;
}

.delete_button{
  background-color: #3c3a3a;
  border: none;
  border-radius: 50px;
  padding: 2px 4px;
  color: #fefdfd;
  cursor: pointer;
}

.delete_button:hover{
  background-color: #61a6ab;
}

.image_box{
  text-align: center;
}

.image_box img{
  max-height: 100px;
  max-width: 80px;
  object-fit: cover;
}
