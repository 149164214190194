.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
  margin-bottom: 0.5rem;
}

.video {
  width: 100%;
  z-index: -1;
}

.logo1 {
  margin-top: -2rem;
  margin-left: 2rem;
}

.logo2 {
  margin-left: 2rem;
}

.line {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid white;
  max-width: 25rem;
}

@media (max-width: 600px) {
  .logo1,
  .logo1Line {
    display: none;
  }
}
