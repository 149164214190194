.icon_container{
  background-color: rgb(72, 181, 163);
  padding: 8px;
  height: 30px;
  width: 30px;
  border-radius: 100px;
  cursor: pointer;
}

.icon{
  color: rgb(65, 64, 64);
  width: 25px;
  height: 23px;
}

.items_length{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 9px;
  top: -5px;
  height: 15px;
  width: 15px;
  border-radius: 10px;
  background-color: aliceblue;
  color: rgb(65, 64, 64);
  font-weight: 600;
  font-size: 13px;
}