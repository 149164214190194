.contact_form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.contact_input {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
  font-family: var(--font-text);
}

.contact_input label {
  color: var(--color-text-light);
}

.input_field {
  color: var(--color-text-dark);
  padding: 0.6rem;
  font-family: var(--font-text);
}

.input_field:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem var(--color-blue);
}

.message {
  resize: none;
}

.map {
  min-width: 100%;
  min-height: 30rem;
  border: 0;
}

@media (min-width: 900px) {
  .map {
    min-height: 40rem;
    width: 100%;
  }
}

@media (max-width: 360px) {
  .map {
    width: 100%;
  }
}
