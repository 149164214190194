.scroll_btn {
  position: fixed;
  bottom: 10rem;
  right: 2rem;
  display: flex;
  width: 5rem;
  height: 5rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  color: var(--color-background);
  background-color: var(--color-carousel-btn);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.6);
  cursor: pointer;
  z-index: 98;
}
