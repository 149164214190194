.tasting_carousel_img,
.restaurant_carousel_img,
.wineTerrace_carousel_img,
.airbnb_carousel_img {
  aspect-ratio: 1032/774;
  width: 100%;
  transition: all 1s;
}

.airbnb_carousel_img {
  aspect-ratio: 1200/800;
}

.tasting_list {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  list-style: none;
  margin-top: 0.6rem;
  font-family: var(--font-text);
}

.tasting_list li {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}

.reserve_btn:link,
.reserve_btn:visited {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
}

.phone_btn {
  color: var(--color-text-light);
  box-shadow: inset 0 0 0 3px currentColor;
  font-family: var(--font-title);
}

.whatsapp_btn {
  background-color: var(--color-text-light);
  color: var(--color-background);
  font-family: var(--font-title);
}

.whatsapp_btn:hover {
  background-color: transparent;
  color: var(--color-text-light);
  box-shadow: inset 0 0 0 3px currentColor;
}

.phone_btn:hover {
  background-color: var(--color-text-light);
  color: var(--color-background);
  box-shadow: none;
}
