.container_box{
  background-color: rgb(213, 214, 216);
  padding: 5px;
}

.table_container{
  height: 550px;
  width: 100%;
  overflow: auto;
}

thead{
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: #625f5f solid 1px;
  z-index: 1000;
}

.table_row:hover{
  background-color: #ede7e7;
}

.search_box{
  display: flex;
  margin-left: 30px;
  margin-bottom: 30px;
  padding: 0px;
} 

.search_box input{
  font-size: 20px;
  padding-left: 5px;
}

.title_info{
  font-size: 17px;
  text-align: center;
  margin: 5px 0px;
}

.ul_info{
  margin-left: 30px;
  margin-bottom: 20px;
  font-size: 12px;
}

.ol_info{
  margin-left: 30px;
  font-size: 12px;
}

.li_info{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
}