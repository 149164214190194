.nav_container header {
  background-color: rgb(65, 64, 64);
  padding: 10px 0px;
}

.button_pages {
  margin: 0px 20px;
  text-transform: none;
}

.button_location{
  margin: 0px 20px;
  padding: 7px 15px;
  color: white;
  text-decoration: none;
  border: solid 1px #fff;
  border-radius: 30px ; 
  display: flex;
}

.button_location:hover{
 /* box-shadow: 3px 3px 6px 2px rgba(191,189,191,0.75);*/
 padding: 6px 14px;
 border: solid 2px #fff;
} 

.experience_link {
  color: white;
  display: block;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  font-family: var(--font-text);
}

.social_link:link,
.social_link:visited {
  color: inherit;
  transition: all 0.3s;
}

.social_link:hover,
.social_link:active {
  transform: scale(1.1);
}


/* @media (min-width: 1040px) {
  .button_location{
    display: flex;
  }
} */