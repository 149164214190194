
.check_container{
  background-color:#121212 ;
  width: 100%;
  height: 100vh;
}

.box_container{
  background-color:#121212 ;
  width: 100%;
  padding: 20px;
}

.box{
  background-color: #3c3a3a;
  color: #fefdfd;
  padding: 20px;
  margin: 15px;
}

.box label{
  font-size: 1.5rem;
  margin-bottom: 5px;
}

.box input{
  height: 4.3rem;
  font-size: 1.6rem;
  padding-left: 10px;
  border-radius: 7px;
}

.box textarea{
  height: 4.3rem;
  font-size: 1.6rem;
  padding-left: 10px;
  border-radius: 7px;
}

.box_labels{
  margin-top: 15px;
}

.items_box{
  width: auto;
  height: 300px;
  overflow: auto;
}

.button_desc{
  margin-top: 10px;
  color: #61a6ab;
  padding: 10px 15px;
  background-color: #3c3a3a;
  border: solid 2px #61a6ab;
}

.button_desc:hover{
  color: #fefdfd;
  background: #61a6ab;
  border-color: #61a6ab;
}

.button_final{
  background-color: #3c3a3a;
  padding: 10px 15px;
  width: 100%;
  margin-top: 7px;
  height: 90px;
  color: #61a6ab;
  border-radius: 50px;
  font-size: 2rem;
  border:none ;
  cursor: pointer;
}

.button_final:hover{
  border:solid 3px #61a6ab ;
  color: #fefdfd;
  background: #61a6ab;
  border-color: #61a6ab;
}

.payment_message {
  color: red;
  font-size: 2rem;
  font-weight: 500;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

/* .spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: '';
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
} */