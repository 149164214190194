.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0.5rem;
  background-color: rgba(0, 0, 0, 0.603);
  z-index: 10;
  margin-bottom: 2rem;
}

.bannerimg {
  width: 100%;
  z-index: -1;
  margin-bottom: 2rem;
}

