@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Symbols:wght@200&display=swap");

* {
  /* La línea de abajo es para visualizar qué es lo que causa el overflow */
  /* background: rgb(0 100 0 / 0.1) !important; */

  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
  --color-blue: #61a6ab;
  --color-gray: #c7c7c7;
  --color-carousel-btn: rgba(97, 166, 171, 0.4);
  --color-background: #1d1a1b;
  /*--color-background: #231f20; */
  --color-background-admin: #d6d6d6;
  --color-text-dark: #121212;
  --color-text-light: #fefdfd;
}

body {
  font-family: "Noto Sans Symbols", sans-serif;
  --font-title: "Noto Sans Symbols", sans-serif;
  --font-text: "Noto Sans Symbols", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-background);
}

.lazy_img {
  filter: blur(10px);
}

.section_hidden {
  opacity: 0;
  transform: translateY(8rem);
}

.section {
  transition: all 1s;
}
