.tasting_text_container span {
  color: var(--color-text-light);
  font-family: var(--font-text);
}

.tasting_details {
  text-transform: uppercase;
  font-family: var(--font-text);
}

.tasting_details span {
  text-transform: none;
}

.gallery_img {
  display: flex;
  aspect-ratio: 2048/1365;
  width: 100%;
  transition: all 0.3s;
  filter: saturate(0.8);
}

.gallery_img:hover {
  transform: scale(1.1);
  cursor: pointer;
  filter: saturate(120%);
}

.img_big {
  aspect-ratio: 2048/1365;
  width: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.6);
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
}

.carousel_img {
  aspect-ratio: 2048/1365;
  width: 100%;
  transition: all 0.3s;
}

.img_container {
  transition: all 0.3s;
}

.carousel_btn {
  display: flex;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  color: var(--color-background);
  background-color: rgba(97, 166, 171, 0.6);
  position: absolute;
  cursor: pointer;
  z-index: 100;
}

.left_btn {
  top: 50%;
  transform: translate(-50%, -50%);
}

.right_btn {
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.tasting_btn:link,
.tasting_btn:visited {
  text-decoration: none;
  text-align: center;
  text-transform: none;
  padding: 4px 0;
  align-self: center;
  background-color: var(--color-blue);
  color: var(--color-background);
  border: none;
  border-radius: 100px;
  font-size: inherit;
  font-weight: 600;
  width: 50%;
  transition: all 0.3s;
}

.tasting_btn:hover,
.tasting_btn:active {
  background-color: #81b8bc;
}

@media (max-width: 900px) {
  .img_big {
    display: none;
  }

  .overlay {
    display: none;
  }
}

@media (max-width: 600px) {
  .left_btn {
    transform: translate(-30%, -50%);
  }

  .right_btn {
    transform: translate(-20%, -50%);
  }
}
