.list{
  margin: 10px 15px;
}

.listItem{
  margin-bottom: 10px;
  color: #000;

}

.listItem a{
  color: #000;
  display: flex;
  text-decoration: none;
}