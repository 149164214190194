.wine_img {
  width: 30rem;
}

.wine_btn {
  cursor: pointer;
  color: var(--color-background);
  font-weight: 600;
  font-family: var(--font-text);
  text-transform: uppercase;
  width: 50%;
  padding: 1rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
}

.wine_btn:hover {
  transform: scale(1.1);
}
