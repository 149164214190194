.box_container{
  background-color: #cdcaca;
  padding: 20px;
  height: 100vh;
}

.table_container{
  height: 550px;
  width: 100%;
  overflow: auto;
}

thead{
  position: -webkit-sticky; /* Safari... */
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  border-bottom: #625f5f solid 1px;
  z-index: 1000;
}

.table_row:hover{
  background-color: #ede7e7;
}