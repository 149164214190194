.social_link:link,
.social_link:visited {
  color: inherit;
  transition: all 0.3s;
}

.social_link:hover,
.social_link:active {
  transform: scale(1.1);
}
