.wine_img {
  aspect-ratio: 432/577;
  height: 40rem;
}

.carousel_btn {
  display: flex;
  width: 4rem;
  height: 4rem;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  color: var(--color-background);
  background-color: var(--color-carousel-btn);
  position: absolute;
  cursor: pointer;
  z-index: 98;
}

.left_btn {
  top: 50%;
  transform: translate(50%, -50%);
}

.right_btn {
  top: 50%;
  right: 0;
  transform: translate(-50%, -50%);
}

.wine_slide {
  background-color: var(--color-background);
}

.wine_btn {
  cursor: pointer;
  color: var(--color-background);
  font-weight: 600;
  font-family: var(--font-text);
  text-transform: uppercase;
  width: 50%;
  padding: 1rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
}

.btn_link {
  display: flex;
  justify-content: center;
  align-self: stretch;
  text-decoration: none;
}

.cart_btn {
  background-color: var(--color-blue);
}

.cart_btn:hover {
  background-color: #81b8bc;
}

.detail_btn:hover {
  color: var(--color-text-light);
  background-color: var(--color-background);
  box-shadow: inset 0 0 0 3px currentColor;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .wine_btn {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .wine_btn {
    width: 100%;
  }
}

@media (max-width: 360px) {
  .wine_img {
    height: 30rem;
  }
}
