.about_us {
  padding-top: 9.6rem;
  background-color: var(--color-background);
}

.worker_img {
  aspect-ratio: 1600/1066;
  width: 100%;
  clip-path: polygon(0 0, 87% 0, 100% 100%, 0% 100%);
}

@media (max-width: 600px) {
  .worker_img {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    margin-bottom: 1.5em;
  }
}
