.subscribe_form {
  grid-column: 1/-1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.subscribe_input:focus {
  outline: none;
  box-shadow: 0 0 0 0.2rem var(--color-blue);
  font-weight: 600;
}

.subscribe_input {
  width: 25rem;
  padding: 1.5rem;
  font-family: var(--font-text);
  border-radius: 0.4rem;
  border: none;
  margin-bottom: 0.5rem;
}

.subscribe_btn {
  cursor: pointer;
  color: var(--color-background);
  font-weight: 600;
  font-family: var(--font-text);
  text-transform: uppercase;
  width: 25rem;
  padding: 1rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
  margin-bottom: 2rem;
}

.subscribe_btn:hover {
  background-color: var(--color-blue);
}

.subscribe_div {
  text-align: center;
}

.subscribe_div:focus-within {
  font-weight: 800;
}

input[type="radio"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  cursor: pointer;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid currentColor;
  border-radius: 50%;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--color-blue);
  /* Windows High Contrast Mode */
  background-color: CanvasText;
}

input[type="radio"]:checked::before {
  transform: scale(1);
}

input[type="radio"]:focus {
  outline-offset: max(2px, 0.15em);
}

/* *,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
} */

.subscribe_div {
  font-family: system-ui, sans-serif;
  font-size: 2rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}

@media (max-width: 360px) {
  .subscribe_input {
    padding: 0.6rem;
  }
}
