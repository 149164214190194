.container {
  background-color: var(--color-background);
  color: var(--color-text-light);
  min-height: 55vh;
}

.wine_btn {
  cursor: pointer;
  color: var(--color-background);
  font-weight: 600;
  text-transform: uppercase;
  width: 50%;
  padding: 1rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
  background-color: var(--color-blue);
  margin-bottom: 2em;
  margin-top: 0.8em;
}

.wine_btn:hover {
  background-color: #81b8bc;
}

.wine_img {
  height: 40rem;
}

.wine_img:hover {
  cursor: pointer;
}

.detail_name {
  text-decoration: none;
}

.detail_btn {
  text-decoration: none;
  letter-spacing: 0.5px;
  text-align: center;
  cursor: pointer;
  color: var(--color-background);
  background-color: var(--color-text-light);
  font-weight: 600;
  font-size: 1.4rem;
  font-family: var(--font-text);
  text-transform: uppercase;
  width: 50%;
  padding: 1rem;
  border-radius: 100px;
  border: none;
  transition: all 0.3s;
}

.detail_btn:hover {
  background-color: #81b8bc;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (min-width: 600px) {
  .wine_btn {
    width: 75%;
  }
}

@media (max-width: 500px) {
  .wine_btn {
    width: 90%;
  }

  .container {
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 200px) {
  .container {
    height: 120vh;
  }
}
