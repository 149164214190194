.products_box{
  background-color: #121212;
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
}

.link_checkout {
  background-color: #61a6ab;
  margin: 10px 30px;
  height: 45px;
  width: auto;
  text-align: center;
  border: none;
  border-radius: 40px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 500;
  color: #121212;
  text-decoration: none;
}

.link_checkout:hover{
  background-color: #3f585a;
  color: #fefdfd;
}

